import React, { useState, useEffect, useCallback } from "react";
import Layout from "../components/layout";

import { useStaticQuery, graphql } from "gatsby";
import test from "../images/test.svg";
import exam from "../images/student.svg";
import student from "../images/students.svg";
import customization from "../images/customization.svg";
import FeatureList from "../components/featurelist";
import ReactFlow, {
  isEdge,
  removeElements,
  addEdge,
  MiniMap,
  Controls,
} from "react-flow-renderer";
import ColorSelectorNode from "../components/ColorSelectorNode";
import zoom_logo from "../images/zoom_logo.png";
import youtube_logo from "../images/youtube_logo.png";
import vimeo_logo from "../images/vimeo_logo.png";
import shopify_logo from "../images/shopify_logo.png";
import woo_logo from "../images/woo.png";
import mobile_logo from "../images/mobile.svg";
import email_logo from "../images/email.png";

const onNodeDragStop = (event, node) => console.log("drag stop", node);
const onElementClick = (event, element) => console.log("click", element);

const initBgColor = "#1A192B";

const connectionLineStyle = { stroke: "#fff" };
const snapGrid = [20, 20];
const nodeTypes = {
  selectorNode: ColorSelectorNode,
};

const IndexPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      shopifyImage: file(relativePath: { eq: "shopify.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [reactflowInstance, setReactflowInstance] = useState(null);
  const [elements, setElements] = useState([]);
  const [bgColor, setBgColor] = useState(initBgColor);

  useEffect(() => {
    const onChange = (event) => {
      setElements((els) =>
        els.map((e) => {
          if (isEdge(e) || e.id !== "2") {
            return e;
          }

          const color = event.target.value;

          setBgColor(color);

          return {
            ...e,
            data: {
              ...e.data,
              color,
            },
          };
        })
      );
    };

    setElements([
      {
        id: "zoom",
        type: "straight",
        data: { label: <img src={zoom_logo} width="100px" height="20px" /> },
        position: { x: 0, y: 0 },
        sourcePosition: "right",
      },
      {
        id: "youtube",
        type: "straight",
        data: { label: <img src={youtube_logo} width="100px" height="20px" /> },
        position: { x: 0, y: 50 },
        sourcePosition: "right",
      },
      {
        id: "vimeo",
        type: "straight",
        data: { label: <img src={vimeo_logo} width="100px" height="20px" /> },
        position: { x: 0, y: 100 },
        sourcePosition: "right",
      },
      {
        id: "shopify",
        type: "straight",
        data: { label: <img src={shopify_logo} width="100px" height="20px" /> },
        position: { x: 50, y: 80 },
        sourcePosition: "top",
        targetHandle: "d",
      },
      {
        id: "woo",
        type: "straight",
        data: { label: <img src={woo_logo} width="100px" height="20px" /> },
        position: { x: 0, y: 50 },
        sourcePosition: "top",
        targetHandle: "d",
      },
      {
        id: "mobile",
        type: "straight",
        data: { label: <img src={mobile_logo} width="30px" height="20px" /> },
        position: { x: 0, y: 50 },
        sourcePosition: "bottom",
        targetHandle: "e",
      },
      {
        id: "email",
        type: "straight",
        data: { label: <img src={email_logo} width="30px" height="20px" /> },
        position: { x: 0, y: 50 },
        sourcePosition: "bottom",
        targetHandle: "e",
      },
      {
        id: "inflowkit",
        type: "selectorNode",
        data: { onChange: onChange, color: initBgColor },
        style: { border: "1px solid #777", padding: 10 },
        position: { x: 300, y: 50 },
      },
      {
        id: "3",
        type: "output",
        data: { label: "Live classes" },
        position: { x: 650, y: 25 },
        targetPosition: "left",
      },
      {
        id: "4",
        type: "output",
        data: { label: "Memberships" },
        position: { x: 650, y: 100 },
        targetPosition: "left",
      },
      {
        id: "5",
        type: "output",
        data: { label: "Courses" },
        position: { x: 650, y: 100 },
        targetPosition: "left",
      },
      {
        id: "6",
        type: "output",
        data: { label: "Downloads" },
        position: { x: 650, y: 100 },
        targetPosition: "left",
      },
      {
        id: "mobile-inflowkit",
        source: "mobile",
        target: "inflowkit",
        animated: true,
        style: { stroke: "#fff" },
        targetHandle: "d",
      },
      {
        id: "shopify-inflowkit",
        source: "shopify",
        target: "inflowkit",
        animated: true,
        style: { stroke: "#fff" },
        targetHandle: "e",
      },
      {
        id: "email-inflowkit",
        source: "email",
        target: "inflowkit",
        animated: true,
        style: { stroke: "#fff" },
        targetHandle: "d",
      },
      {
        id: "woo-inflowkit",
        source: "woo",
        target: "inflowkit",
        animated: true,
        style: { stroke: "#fff" },
        targetHandle: "e",
      },
      {
        id: "zoom-inflowkit",
        source: "zoom",
        target: "inflowkit",
        animated: true,
        style: { stroke: "#fff" },
      },
      {
        id: "youtube-inflowkit",
        source: "youtube",
        target: "inflowkit",
        animated: true,
        style: { stroke: "#fff" },
      },
      {
        id: "vimeo-inflowkit",
        source: "vimeo",
        target: "inflowkit",
        animated: true,
        style: { stroke: "#fff" },
      },
      {
        id: "e2a-3",
        source: "inflowkit",
        target: "3",
        sourceHandle: "a",
        animated: true,
        style: { stroke: "#fff" },
      },
      {
        id: "e2b-4",
        source: "inflowkit",
        target: "4",
        sourceHandle: "a",
        animated: true,
        style: { stroke: "#fff" },
      },
      {
        id: "e2b-4",
        source: "inflowkit",
        target: "5",
        sourceHandle: "a",
        animated: true,
        style: { stroke: "#fff" },
      },
      {
        id: "e2b-4",
        source: "inflowkit",
        target: "6",
        sourceHandle: "a",
        animated: true,
        style: { stroke: "#fff" },
      },
    ]);
  }, []);

  useEffect(() => {
    if (reactflowInstance && elements.length > 0) {
      reactflowInstance.fitView();
    }
  }, [reactflowInstance, elements.length]);

  const onElementsRemove = useCallback(
    (elementsToRemove) =>
      setElements((els) => removeElements(elementsToRemove, els)),
    []
  );
  const onConnect = useCallback(
    (params) =>
      setElements((els) =>
        addEdge({ ...params, animated: true, style: { stroke: "#fff" } }, els)
      ),
    []
  );

  const onLoad = useCallback(
    (rfi) => {
      if (!reactflowInstance) {
        setReactflowInstance(rfi);
        console.log("flow loaded:", rfi);
      }
    },
    [reactflowInstance]
  );

  return (
    <ReactFlow
      elements={elements}
      style={{ background: '#265cb0', height: "100vh", width: "100%" }}
      onElementClick={onElementClick}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onNodeDragStop={onNodeDragStop}
      onLoad={onLoad}
      nodeTypes={nodeTypes}
      connectionLineStyle={connectionLineStyle}
      snapToGrid={true}
      snapGrid={snapGrid}
    />
  );
};
export default IndexPage;
