import React, { memo } from "react";
import inflowkit_logo from "../images/logo.png";

import { Handle } from "react-flow-renderer";
export default memo(({ data }) => {
  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
      />
      <div>
        <img src={inflowkit_logo} width="100px" />
      </div>

      <Handle
        type="source"
        position="right"
        id="a"
        style={{ background: "#555" }}
      />

      <Handle
        type="target"
        position="top"
        id="d"
        style={{ background: "#555" }}
      />
      <Handle
        type="target"
        position="bottom"
        id="e"
        style={{ background: "#555" }}
      />
    </>
  );
});
